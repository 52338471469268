<template>
  <div v-if="!isFromDocument">
    <div
      :class="
        data && data.validations.vertical_line ? 'vertical-line' : 'horizontal'
      "
      :style="getStyle"
    ></div>
    <span v-if="isActive" class="setting-icon" @click="openSettings">
      <i class="el-icon-s-tools" />
    </span>
  </div>
  <div v-else>
    <div class="horizontal" :style="getStyle"></div>
  </div>
</template>

<script>
// import {bus} from '../../../main';
export default {
  name: "HorizontalLineView",
  props: ["field", "data", "isActive", "isFromDocument"],
  data() {
    return {
      isHorizontal: "",
    };
  },
  mounted() {},
  computed: {
    isVerticalLine() {
      return this.data?.validations?.vertical_line ?? false;
    }, 
    getStyle() {
    if (!this.isVerticalLine) {
      return `border-bottom:${
        this.data?.styles?.line_size ?? 4
      }px solid ${this.data?.styles?.line_color ?? "#00000"};width:${
        this.data?.width
      }px;`;
    }
    return `border-left:${
      this.data?.styles?.line_size ?? 4
    }px solid ${this.data?.styles?.line_color ?? "#00000"};width:20px;height:${
      this.data?.height
    }px;`;
  },
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style lang="scss">
.vertical-line {
  border-left: 1px solid black;
  display: flex;
  //  border-left: 1px solid black; /* Change the color and width as desired */
  // height: 100%; /* Change the height as desired */
}
.horizontal:after {
  content: " ";
  display: block;
  border-bottom: 0.1px solid;
}
</style>
